exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-berichten-js": () => import("./../../../src/pages/berichten.js" /* webpackChunkName: "component---src-pages-berichten-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-doe-maar-duurzaam-js": () => import("./../../../src/pages/doe-maar-duurzaam.js" /* webpackChunkName: "component---src-pages-doe-maar-duurzaam-js" */),
  "component---src-pages-energieverbruik-verlagen-js": () => import("./../../../src/pages/energieverbruik-verlagen.js" /* webpackChunkName: "component---src-pages-energieverbruik-verlagen-js" */),
  "component---src-pages-esg-beleid-wordt-de-norm-voor-bedrijven-en-gebouwen-wat-betekent-dit-voor-u-js": () => import("./../../../src/pages/esg-beleid-wordt-de-norm-voor-bedrijven-en-gebouwen-wat-betekent-dit-voor-u.js" /* webpackChunkName: "component---src-pages-esg-beleid-wordt-de-norm-voor-bedrijven-en-gebouwen-wat-betekent-dit-voor-u-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interview-gijs-energiemonitoring-js": () => import("./../../../src/pages/interview-gijs-energiemonitoring.js" /* webpackChunkName: "component---src-pages-interview-gijs-energiemonitoring-js" */),
  "component---src-pages-monitoring-js": () => import("./../../../src/pages/monitoring.js" /* webpackChunkName: "component---src-pages-monitoring-js" */),
  "component---src-pages-offerte-js": () => import("./../../../src/pages/offerte.js" /* webpackChunkName: "component---src-pages-offerte-js" */),
  "component---src-pages-referenties-js": () => import("./../../../src/pages/referenties.js" /* webpackChunkName: "component---src-pages-referenties-js" */),
  "component---src-pages-samenwerking-beyond-energy-js": () => import("./../../../src/pages/samenwerking-beyond-energy.js" /* webpackChunkName: "component---src-pages-samenwerking-beyond-energy-js" */),
  "component---src-pages-werkwijze-energiemonitoring-js": () => import("./../../../src/pages/werkwijze-energiemonitoring.js" /* webpackChunkName: "component---src-pages-werkwijze-energiemonitoring-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

